import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../BurgerMenu.css'
import 'font-awesome/css/font-awesome.min.css';
import { homepage } from '../../package.json'
import { cuisineObject, locations } from '../lib/consts'
import { labelise } from '../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import './Header.css'

export default () => (
  <div className='navbar-menu' style={{display: 'inline-block'}}>
      <NavbarButton label='Home' exact link={homepage} />
      <LocationDropDown />
      <CuisineDropDown />
      <NavbarButton label='Top Recommendations' link={`${homepage}/blogs/top-recommendations`} />
      <NavbarButton label='Maps' link={`${homepage}/map`} />
      <NavbarButton label='About' link={`${homepage}/about`} />
  </div>
)

const LocationDropDown = () => {
  const dropdownItems = locations.map( location => <NavLink to={`${homepage}/blogs/location/${location}/`} exact className="navlink sub" activeClassName="navlink sub active">{labelise(location).split(' ')[0] + ' London'}</NavLink> )
  return <NavbarButton label='London' link={`${homepage}/blogs/location/`} dropdownItems={dropdownItems} width='auto' />
}

const CuisineDropDown = () => {
  const asianDropDownItems = [
    <NavLink to={`${homepage}/blogs/cuisine/east-asian`} exact className="navlink sub" activeClassName="navlink sub active">East Asian</NavLink>,
    <NavLink to={`${homepage}/blogs/cuisine/south-asian`} exact className="navlink sub" activeClassName="navlink sub active">South Asian</NavLink>,
    <NavLink to={`${homepage}/blogs/cuisine/south-east-asian`} exact className="navlink sub" activeClassName="navlink sub active">South East Asian</NavLink>,
  ]
  const asianDropdownButton = <NavbarButton label='Asian' link={`${homepage}/blogs/cuisine/asian`} dropdownItems={asianDropDownItems} nested />
  const dropdownItems = cuisineObject.slice(1).map( cuisine => <NavLink to={`${homepage}/blogs/cuisine/${cuisine.name}`} exact className="navlink sub" activeClassName="navlink sub active">{cuisine.label}</NavLink> )

  return <NavbarButton label='Cuisine' link={`${homepage}/blogs/cuisine`} dropdownItems={[asianDropdownButton, ...dropdownItems]} />
}


const NavbarButton = ( { label, link, dropdownItems, nested, exact, width } ) => {
  const style = {
    position: !nested && 'relative',
    padding: nested ? 0 : '0.5em',
    paddingBottom: nested ? 0 : '2em',
  }

  const caretStyle = {
    marginLeft: 12,
  }

  const [ dropdownHidden, setDropdownHidden ] = useState( true )
  const dropdownClick = ( e ) => {
    e.preventDefault()
    setDropdownHidden( false )
  }
  useEffect( () => {
    const clickHandler = window.addEventListener( 'click', ( e ) => e.target.text !== label && setDropdownHidden( true ) )

    return clickHandler
  }, [ label ] )

  return !dropdownItems ? (
    <span className='navbar-button' style={style}>
        <NavLink to={link} className="navlink" exact={exact} activeClassName="navlink active">{label}</NavLink>
    </span>
  ) :
  (
    <span className='navbar-button dropdown-button' style={style} onMouseEnter={dropdownClick} onMouseLeave={() => setDropdownHidden( true )}>
        <NavLink to={link} className={`navlink${nested ? ' sub' : ''}`} exact={exact} activeClassName={`navlink${nested ? ' sub' : ''} active`} onClick={dropdownClick}>
          {label}
          { <FontAwesomeIcon style={caretStyle} icon={nested ? faCaretRight : faCaretDown} /> }
        </NavLink>
        <DropdownElement items={dropdownItems} hidden={dropdownHidden} setHidden={setDropdownHidden} nested={nested} width={width}/>
    </span>
  )

}

const DropdownElement = ( { hidden, setHidden, items, nested, width } ) => {
  const transitionTime = '400ms'
  const style = {
    position: 'absolute',
    top: nested ? -1 : 44,
    left: nested ? 185 : 7,
    backgroundColor: 'white',
    zIndex: hidden ? -1 : 10,
    opacity: hidden ? 0 : 1,
    textAlign: 'center',
    boxShadow: '0px 10px 10px 2px rgba(0,0,0,0.55)',
    border: 0,
    // border: '0.5px solid black',
    // borderTop: 0,
    // transition: 'max-height 500ms',
    // maxHeight: hidden ? 0 : 500,
    transform: hidden ? 'scaleY(0)' : 'scaleY(1)',   
    transformOrigin: 'top',
    transition: '0.26s ease',
  }
  const listStyle = {
    opacity: hidden ? 0 : 1,
    listStyle : 'none',
    padding: 0,
    margin: 0,
    transitionDelay: '300ms',
    transition: '400ms',
  }

  const elementStyle = {
    // textAlign: 'center',
    // opacity: hidden ? 0 : 1,
    fontSize: '0.8em',
    padding: 0,
    width: width ? width : nested ? 130 : 190,
  }

  return  (
    <div className={`dropdown-element${nested ? ' nested' : ''}`} style={style}>
      <ul style={listStyle}>
        {items.map( item => <li style={elementStyle} onClick={() => setHidden( true )}>{item}</li> )}
      </ul>
    </div>
  )
}

