import React from 'react'
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import useScreen from "./useScreen"
import SocialIcon from "./SocialIcon"
import moment from 'moment'

export default () => {
    const isMobile = useScreen()
    return (
        <div className="footer">
            <p className='footer-item'>© Bangingbites {moment().year()} | <Link to='/privacy'>Privacy Policy</Link></p>
            <h2 className='footer-item' style={{fontFamily: 'Pattaya'}}><Link to='/' onClick={() => scrollToTop()}>Bangingbites</Link></h2>
            <span className='footer-item' style={{fontSize: '1.6em'}}>
                <SocialIcon icon={<FontAwesomeIcon icon={faEnvelope}  />} url="mailto:bangingbites@gmail.com" />
                <SocialIcon icon={<FontAwesomeIcon icon={faInstagram} />}  url="https://instagram.com/bangingbites" />
                <SocialIcon icon={<FontAwesomeIcon icon={faPinterest} />}  url="https://www.pinterest.co.uk/bangingbites" />
            </span> 
        </div>
    ) 
}

const scrollToTop = () => window.scrollTo(0,0)
