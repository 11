import React from 'react'
import { Helmet } from 'react-helmet'
import Loader from 'react-loader-spinner'
import useScreen from '../components/useScreen'
import { Reveal, Image, Card, Container } from 'semantic-ui-react'
import Image1 from '../assets/img (1).jpg'
import Image2 from '../assets/img (2).jpg'
import { useHistory } from 'react-router'
import Footer from '../components/Footer'
import { homepage } from '../../package.json'
import { useEffect } from 'react'
import { useState } from 'react'
import { getAllPosts } from '../lib/API'
import { convertTitleToUrl, getRandomFromArray, getSetDifference, promiseState } from '../lib/utils'
import { buttonStyle } from '../lib/consts'

const Overlay = ({ title, mobile }) => {
    const overlayStyle = { 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        backgroundColor: 'rgba(255,255,255,0.5)', 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%',
    }

    const textStyle = {
        fontFamily: 'Pattaya',
        padding: !mobile ? '8%' : '5%',
        fontWeight: 1,
        border: '3px solid black',
        fontSize: !mobile ? '2rem' : '1.2rem',
        minWidth: 140,
        maxWidth: 250
    }

    return  (
        <div className='image-overlay' style={overlayStyle}>
            <h2 style={textStyle}>{title}</h2>
        </div>
    )
}


const Home = () => {

    const [ allPosts, setAllPosts ] = useState( [] )
    const [ posts, setPosts ] = useState( [] )
    const [ loadCount, setLoadCount, getLoadCount ] = useState( 0 )
    const [ loaded, setLoaded ] = useState( false )
    const [ page, setPage ] = useState( 1 )
    const history = useHistory()
    const isMobile = useScreen()
    const itemsPerPage = 24

    const loaderStyle = {
        position: 'fixed',
        top: 'calc(50% - 125px)',
        left: 'calc(50% - 125px)',
        transition: '500ms',
        opacity: !loaded ? 1 : 0,
    }
        
    useEffect( () => {
        const getRandomPosts = async () => {
            setLoaded( false )
            setLoadCount( 0 )
            const data = await getAllPosts()
            setAllPosts( data )
            const randomPosts = await getRandomFromArray( data, itemsPerPage )
            setPosts( randomPosts || [] )
            setLoadCount( 1 )
        }
        
        // [...document.getElementsByTagName("img")].forEach( async img => {
        //     img.onload = async () => await promiseState( setLoadCount,  loadCount+1 )
        //     setLoaded( false )
        // })
    
        getRandomPosts()

    }, [] )

    useEffect( () => {
        console.log( loaded )
        if( loadCount === 1 )
            setTimeout(
                () => {
                    setLoaded( true )
                },
                page === 1 ? 1000 : 500
            )
            
    }, [ loadCount ])


    useEffect(() => {
        
        const getMorePosts = async () => {
            if( posts.length < allPosts.length ){
                setLoaded( false )
                setLoadCount( 0 )
                const unselectedPosts = getSetDifference( new Set( allPosts ),  new Set( posts ) )
                const newPostAmount = unselectedPosts.size >= itemsPerPage ? itemsPerPage : unselectedPosts.size
                const newPosts = await getRandomFromArray( [ ...unselectedPosts ], newPostAmount )
                setPosts( [...posts, ...newPosts ] )
                setLoadCount( 1 )
            }
        }

        // [...document.getElementsByTagName("img")].forEach( async img => {
        //     img.onload = async () =>  await promiseState( setLoadCount,  loadCount + 1 )
        //     setLoaded( false )
        // })

        getMorePosts()
        

    }, [ page ] )

    const displayPosts = loaded || page > 1 ? posts : posts.slice( 0, 4 )
    const loadNewOpacity = page > 1 ? 0.5 : 0
    
    return (
        <>
            <Helmet>
                <title>Bangingbites - London Restaurant Review and Food Blog</title>
                <meta name="description" content="A London restaurant review site that offers a user-friendly guide to reflect the diversity in cuisine this city has to offer!" />
            </Helmet>
            <Loader style={loaderStyle} type="Puff" color="#E7D1FF" height={250} width={250} /> 
            <Container className='home-container' fluid style={{width: 'auto', opacity: loaded ? 1 : loadNewOpacity, transition: '500ms'}}>
                <Card.Group doubling itemsPerRow={4} padded centered>
                    {displayPosts.map( ( post ) =>
                    <Card data-aos='fade-up' data-aos-duration={600} data-aos-mirror='true' onClick={() => history.push(`${homepage}/blog/${post.permalink_slug}`, { post } )}>
                        <Reveal animated='fade' instant>
                            <Reveal.Content visible >
                                <Image src={post.cover_images[0].url || Image1} />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Image src={post.cover_images[1].url || Image2} />
                                <Overlay title={post.title || 'Japes'} mobile={isMobile} />
                            </Reveal.Content>
                        </Reveal>
                    </Card>
                             
                    )}  
                </Card.Group>
                {posts.length < allPosts.length && <button style={buttonStyle} className="load-more-button" onClick={() => setPage( page + 1 )}>Load More</button>}
            </Container>
            <Footer/>
        </>
    )
}


    


export default Home
