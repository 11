import qs from 'qs'

const APIRoot = "https://bangingbites.com/api"
const blogPostEndpoint = `${APIRoot}/blog-posts`

const cachedFetch = async (url, options) => {
    let expiry = 60 * 60 // 5 min default
    if (typeof options === 'number') {
      expiry = options
      options = undefined
    } else if (typeof options === 'object') {
      // I hope you didn't set it to 0 seconds
      expiry = options.seconds || expiry
    }
    // Use the URL as the cache key to sessionStorage
    let cacheKey = url
    let cached = localStorage.getItem(cacheKey)
    let whenCached = localStorage.getItem(cacheKey + ':ts')
    if (cached !== null && whenCached !== null) {
      // it was in sessionStorage! Yay!
      // Even though 'whenCached' is a string, this operation
      // works because the minus sign converts the
      // string to an integer and it will work.
      let age = (Date.now() - whenCached) / 1000
      if (age < expiry) {
        let response = new Response(new Blob([cached]))
        return Promise.resolve(response)
      } else {
        // We need to clean up this old key
        localStorage.removeItem(cacheKey)
        localStorage.removeItem(cacheKey + ':ts')
      }
    }
  
    return fetch(url, options).then(response => {
      // let's only store in cache if the content-type is
      // JSON or something non-binary
      if (response.status === 200) {
        console.log(JSON.stringify("$"))

        response.clone().json().then( content => {
          try {
            localStorage.setItem(cacheKey, JSON.stringify(content))
            localStorage.setItem(cacheKey+':ts', Date.now())
          }catch( e ) {
            localStorage.clear()
          }
        
        } )
      }
      return response
    } )
  }

const formatPosts = data => data && data.map( post => ({
    ...post,
    cover_images: post.cover_images && post.cover_images.map( img_data => ({ ...img_data, url: `${APIRoot}${img_data.url}`} ) ),
    collage_images: post.collage_images && post.collage_images.map( img_data => ({ ...img_data, url: `${APIRoot}${img_data.url}`} ) ),
    contents: post.contents.replace( /http:\/\/159.65.215.105\/api/g, APIRoot ) 
} ) )


export const getAllPosts = () => cachedFetch( `${blogPostEndpoint}?_sort=created_at:DESC` ).then( res => res.json() ).then( data => formatPosts(data) ).catch( err => [] )

export const getSinglePost = id => cachedFetch( `${blogPostEndpoint}?permalink_slug=${id}` )
  .then( res => res.json() )
  .then( data => {
    const formattedData = formatPosts(data)
    return formattedData && formattedData.length > 0 ? formattedData[0] : { err: 'No result found' }
  } )
  .catch( err => ( { err } ) )

export const searchPosts = ( queryString ) => {
  const nonwords = ["", " "]
  const queryWords = queryString.split( "-" ).filter( word => !nonwords.includes( word ) ).map( word => encodeURIComponent( word ) )
  const conditions = queryWords.reduce( ( conds, word ) => {
    conds = [ ...conds, { _or: [ { title_contains: word  }, { contents_contains: word },  { permalink_slug_contains: word } ] } ]
    return conds
  }, [] )
  const query = qs.stringify( { _where : conditions } )
  return cachedFetch( `${blogPostEndpoint}?${query}` ).then( res => res.json() ).then( data => formatPosts( data ) ).catch( err => [] )
}

export const getRecommendedPosts = () => cachedFetch( `${blogPostEndpoint}?rating_gte=9&_sort=rating:DESC,created_at:DESC` ).then( res => res.json() ).then( data => formatPosts(data) ).catch( err => [] )

export const queryPosts = ( params ) => {
    const queryParams =  Object.keys( params ).reduce( ( obj, param ) => {
        return params[param] ? {...obj, [param]: encodeURIComponent( params[param].replaceAll('-','_') ) } : obj
    }, {} )

    const query = qs.stringify( 
    { _where : [ 
        { area: queryParams['area']  }, 
        { _or: [ 
            { cuisine: queryParams['cuisine']  }, 
            { secondary_cuisine: queryParams['cuisine'] } 
        ] } 
    ] } )

    return cachedFetch( `${blogPostEndpoint}?${query}&_sort=created_at:DESC` ).then( res => res.json() ).then( data => formatPosts(data) ).catch( err => [] )

}