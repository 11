import React from 'react'
import { Link } from 'react-router-dom'


export default ( { icon, url, className, children, style, onClick } ) => {
return (<div style={{display: "inline", cursor: "pointer", ...style}} onClick={onClick}><a rel="noopener noreferrer" target="_blank" className={`social-icon${!className ? "" : ` ${className}`}`} href={url}>{icon}{children}</a></div>)
}

export const BigSocialIcon =  ( { icon, url, className } ) => {
return (<div style={{display: "inline", cursor: "pointer"}}><a rel="noopener noreferrer" target="_blank" className={`social-icon${!className ? "" : ` ${className}`}`} href={url}><span style={{position: "relative", zIndex: -1}}>{icon}</span></a></div>)
}

