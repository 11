import React from 'react'
import { useHistory } from 'react-router-dom';

import { Image, Card, Grid, Reveal } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked, faPoundSign, faStar } from '@fortawesome/free-solid-svg-icons';
import { homepage } from '../../package.json'
import useScreen from './useScreen';

const BlogCard = ( props ) => {
    // const cardTextStyle = {fontSize: '1em', textAlign:'right'}
    const isMobile = useScreen()
    const history = useHistory()

    const { id, cover_images, rating, price, title, area, specific_area, permalink_slug } = props
    const img1 = cover_images && cover_images[0].url
    const img2 = cover_images && cover_images[1].url
    const infoTextStyle = {
        padding: isMobile && 0
    }
    const displayArea = specific_area ? (specific_area[0].toUpperCase() + specific_area.slice(1)) : (area[0].toUpperCase() + area.slice(1))
    return (
        <Card fluid raised data-aos='fade-up' data-aos-duration={600} data-aos-mirror='true' key={id} className='blog-card' style={{ marginBottom: '5em', marginLeft: '1em', marginRight: '1em' }}>
            <Reveal className='blog-post-cover' animated='move left' onClick={(_) => history.push(`${homepage}/blog/${permalink_slug}`, { post: props } )}>
                <Reveal.Content visible>
                    <Image size='large' src={img1} />
                </Reveal.Content>
                <Reveal.Content hidden>
                    <Image size='large' src={img2} />
                </Reveal.Content>
            </Reveal>
            {/* <Image src={img} onClick={(_) => history.push(`${homepage}/blog/${id}`, { post: props } )} /> */}
            <Card.Content className='blog-card-content' onClick={(_) => history.push(`${homepage}/blog/${permalink_slug}`, { post: props } )}>
                <Card.Header style={{textAlign: 'center', fontFamily: 'Pattaya', fontSize: '2em', marginTop: '0.5em'}}>{title}</Card.Header>
                <Card.Description style={{fontSize: '1em'}}>
                    <Grid>
                        <Grid.Row style={{margin: '0.5em 0', fontSize: '1.5em'}}>
                            <Grid.Column textAlign='center' style={infoTextStyle} width={6}>
                                <FontAwesomeIcon icon={faMapMarked} />
                                <p style={{marginTop: '0.5em'}}>{displayArea}</p>
                            </Grid.Column>
                            <Grid.Column textAlign='center' style={infoTextStyle}  width={4}>
                                <FontAwesomeIcon icon={faStar} />
                                <p style={{marginTop: '0.5em'}}>{rating}/10</p>
                            </Grid.Column>
                            <Grid.Column textAlign='center' width={6} style={infoTextStyle} >
                                <FontAwesomeIcon icon={faPoundSign} />
                                <p style={{marginTop: '0.5em'}}>£{price} pp</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Description>
            </Card.Content>
        </Card>       
    )
}

export default BlogCard