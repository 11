import React, { useContext, useEffect, useState } from 'react';
import {Helmet} from 'react-helmet'
import useScreen from '../components/useScreen'
import { Card, Grid, Transition } from 'semantic-ui-react'
import Image1 from '../assets/img (1).jpg'
import Image2 from '../assets/img (2).jpg'
import Image3 from '../assets/img (3).jpg'
import { useLocation, withRouter } from 'react-router-dom';
import Footer from '../components/Footer';
import BlogCard from '../components/BlogCard'
import SideMenu from '../components/SideMenu'
import { LoaderFitted } from '../components/Loader'
import { titleTextStyle, titleTextStyleTopRecommended } from '../lib/consts' 
import { getRandomFromArray, labelise } from '../lib/utils'
import { getRecommendedPosts, queryPosts } from '../lib/API';
import NoResultsText from '../components/NoResultsText';



const PageTitle = ( { style } ) => {
    const isMobile = useScreen()
    const location = useLocation()
   
    const [ filterType, activeFilter, secondaryFilter ] = location.pathname.split('/blogs/')[1].split('/')
    const specialCases = [ 'vegan', 'burgers-and-chicken', 'desserts', 'brunch' ]

    const titleText = labelise( activeFilter )
    const secondaryTitleText = secondaryFilter && labelise( secondaryFilter )
    let title = ''
    if( secondaryFilter ) {
        if( specialCases.includes( activeFilter ) ||  specialCases.includes( secondaryFilter ))
            title = filterType === 'cuisine' ? `${titleText} in ${secondaryTitleText}` : `${secondaryTitleText} in ${titleText}`
        else
            title = filterType === 'cuisine' ? `${titleText} Cuisine in ${secondaryTitleText}` : `${secondaryTitleText} Cuisine in ${titleText}` 
    } else {
        if( specialCases.includes( activeFilter ) )
            title = filterType === 'cuisine' ? `${titleText}` : `${titleText}`
        else 
            title = filterType === 'cuisine' ? `${titleText} Cuisine` : `${titleText}`
    }

    return <h2 className='page-title' style={titleTextStyle(isMobile)} >{title}</h2>
} 


const ResultsPage = ( { history, location } ) => {
    const isMobile = useScreen()
    const isTopRecommended = location.pathname.includes( 'top-recommendations' )
    const columnWidth = isTopRecommended ? 16 : 14
    const [ filterType, activeFilter, secondaryFilter ] = location.pathname.split('/blogs/')[1].split('/')
    const cuisineFilter = filterType === "cuisine" ? activeFilter : secondaryFilter
    const locationFilter = filterType === "location" ? activeFilter : secondaryFilter

    const [ posts, setPosts ] = useState( null )

    const getHeader = (locationFilter, cuisineFilter) => {
        console.log(locationFilter, cuisineFilter)
        if(locationFilter && !cuisineFilter) {
            return `${labelise(locationFilter)}: Bangingbites reviews in ${labelise(locationFilter)}`
        }else{
            if(!(['brunch', 'desserts', 'vegan'].includes(cuisineFilter)))
                return `${labelise(cuisineFilter)}: Our reviews for ${labelise(cuisineFilter)} Cuisine in ${locationFilter ? labelise(locationFilter) : 'London' }`
            else
                return `${labelise(cuisineFilter)}: Our reviews of ${labelise(cuisineFilter === 'desserts' ? 'dessert' : cuisineFilter)} spots in ${locationFilter ? labelise(locationFilter) : 'London' }`
        }
    }

    useEffect( () => {
        const getPosts = async () => {
            const queryParams = { cuisine: cuisineFilter, area: locationFilter && locationFilter.replace("-london","")}
            let newPosts = isTopRecommended ? await getRecommendedPosts() : await queryPosts( queryParams )
            newPosts = isTopRecommended ? getRandomFromArray( newPosts, newPosts.length ) : newPosts
            !posts ? setTimeout(() => setPosts( newPosts ), 700) : setPosts( newPosts )
        }

        
        getPosts()
    
        // return () => {
            
        //     var scrollPos = window.scrollY
        //     localStorage.setItem("results_page_scroll", scrollPos)
        // }
        

        

    }, [ cuisineFilter, locationFilter, isTopRecommended ] )


    // useEffect(() => {
    //     setTimeout(() => {
    //         const scrollPos = localStorage.getItem("results_page_scroll")
    //         if( scrollPos !== null ) {
    //             window.scrollBy(0, scrollPos)
    //             localStorage.removeItem("results_page_scroll")
    //             console.log(window.scrollY)
    //         }
    //     }, 500)
        
    // }, [ posts ])

    return !isMobile ? (
        <>
            {!isTopRecommended ?
                // <Helmet>
                //     {/* <title>A London restaurant review site that offers a user-friendly guide to reflect the diversity in cuisine this city has to offer!</title> */}
                //     {/* <title>{locationFilter ? `${labelise(cuisineFilter)} Cuisine in ${labelise(locationFilter)}` : `${labelise(cuisineFilter)} Cuisine`} - Banging Bites</title> */}
                //     {/* <meta name="description" content={locationFilter ? `${labelise(cuisineFilter)} Cuisine in ${labelise(locationFilter)}` : `${labelise(cuisineFilter)} Cuisine`} /> */}
                // </Helmet>
                <Helmet>
                    <title>{getHeader(locationFilter, cuisineFilter)}</title>
                    <meta name="description" content="A London restaurant review site that offers a user-friendly guide to reflect the diversity in cuisine this city has to offer!" />
                </Helmet>
                :
                <Helmet>
                    <title>Top Recommendations: Bangingbites' top restaurant and eatery picks</title>
                    <meta name="description" content='A compilation of our top picks for restaurants in London!' />
                </Helmet>
            }
            <div className="home-container">
                <Grid centered style={{margin: '0 auto', padding: '0 3em'}} padded stackable>
                    {isTopRecommended && <h1 style={titleTextStyle(isMobile)}>Top Recommendations</h1>}
                    <Grid.Row style={{paddingTop: 0}}>
                        {!isTopRecommended && 
                            <Grid.Column width={2} style={{paddingLeft: '1.5em'}}>
                                <SideMenu />

                            </Grid.Column>
                        }
                        <Grid.Column width={columnWidth} style={{padding: !isTopRecommended ? '0 4em' : '0 5vw'}}>
                        {!isTopRecommended && <PageTitle />}
                        {/* <PageTitle /> */}
                            <Card.Group stackable itemsPerRow={3}>
                                {!posts && <LoaderFitted loaded={posts !== null} /> }
                                {posts && posts.length > 0  && posts.map( post => <BlogCard {...post} />)}
                                {posts && posts.length === 0 && <NoResultsText text="No Posts" />}
                            </Card.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <Footer />
        </>
        
  ) : (
    <>
        {!isTopRecommended ?
            // <Helmet>
            //     {/* <title>A London restaurant review site that offers a user-friendly guide to reflect  the diversity in cuisine this city has to offer!</title> */}
            //     {/* <title>{locationFilter ? `${labelise(cuisineFilter)} Cuisine in ${labelise(locationFilter)}` : `${labelise(cuisineFilter)} Cuisine`} - Banging Bites</title> */}
            //     {/* <meta name="description" content={locationFilter ? `${labelise(cuisineFilter)} Cuisine in ${labelise(locationFilter)}` : `${labelise(cuisineFilter)} Cuisine`} /> */}
            // </Helmet>
            <Helmet>
                <title>{getHeader(locationFilter, cuisineFilter)}</title>
                <meta name="description" content="A London restaurant review site that offers a user-friendly guide to reflect the diversity in cuisine this city has to offer!" />
            </Helmet>
            :
            <Helmet>
                <title>Top Recommendations: Bangingbites' top restaurant and eatery picks</title>
                <meta name="description" content='A compilation of our top picks for restaurants in London!' />
            </Helmet>
        }
        <div className="home-container">
            <Grid centered style={{margin: '0 auto', padding: '0 3em'}} padded stackable>
                {isTopRecommended && <h1 style={titleTextStyle(isMobile)}>Top Recommendations</h1>}
                {!isTopRecommended && <PageTitle />}
                <Grid.Row style={{paddingTop: 0}}>
                    {!isTopRecommended && 
                        <Grid.Column width={2} style={{paddingLeft: '1.5em'}}>
                            <SideMenu />

                        </Grid.Column>
                    }
                    <Grid.Column width={columnWidth} style={{padding: !isTopRecommended ? '0 4em' : '0 5vw'}}>
                    
                    {/* <PageTitle /> */}
                        <Transition.Group as={Card.Group} stackable doubling itemsPerRow={3} animation='drop' duration={600}>
                            {/* {results.map( img => <BlogCard img={img} />)} */}
                            {/* {posts.map( post => <BlogCard {...post} />)} */}
                            {!posts && <LoaderFitted loaded={posts !== null} isMobile={isMobile}/> }
                            {posts && posts.length > 0  && posts.map( post => <BlogCard {...post} />)}
                            {posts && posts.length === 0 && <NoResultsText text="No Posts" />}
                        </Transition.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
        <Footer />
    </>
)
}

export default withRouter( ResultsPage )