import React, { useState } from 'react'
import { Menu } from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom';
import { homepage } from '../../package.json'
import { sideMenuCuisines } from '../lib/consts'
import useScreen from '../components/useScreen'

const ResponsiveSideMenu = () => {
    const history = useHistory()
    const location = useLocation()
    const isMobile = useScreen()

    const [ filterType, activeFilter ] = location.pathname.split('/blogs/')[1].split('/')

    const [ activeCuisine, setActiveCuisine ] = useState(activeFilter)
    const [ activeLocation, setActiveLocation ] = useState(location.pathname.split('/blogs/')[1].split('/')[0])

    const handleItemClick = ( e, { name }) => {
        // Handle click on cuisine
        setActiveCuisine(name) 
        const [ locationFilter ] = location.pathname.split('/blogs/location/')[1].split('/')
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if( !name.includes( 'all' ) ){
            history.push(`${homepage}/blogs/location/${locationFilter}/${name}`)
        } else {
            history.push(`${homepage}/blogs/location/${locationFilter}`)
        }
        
       
    }
    const handleLocationClick = ( e, { name } ) => {
        // Handle click on location
        setActiveLocation( name )
        const [ cuisineFilter ] = location.pathname.split('/blogs/cuisine/')[1].split('/')
        window.scrollTo( 0, 0 )
        if( !name.includes( 'all' ) ){
            history.push(`${homepage}/blogs/cuisine/${cuisineFilter}/${name}`)
        } else {
            history.push(`${homepage}/blogs/cuisine/${cuisineFilter}`)
        }
    }
    
    
    return (
        <div style={{position: 'sticky', top: isMobile ? 0 : 100, marginTop: !isMobile ? 120 : 0}}>
            {filterType === 'location' && <Menu className='cuisine-side-menu' text vertical>
            <Menu.Item header>Cuisine</Menu.Item>
            <Menu.Item
                name='all-cuisine'
                active={activeCuisine === 'all-cuisine'}
                onClick={handleItemClick}
            />
            {sideMenuCuisines.map( name => <Menu.Item
                name={name}
                active={activeCuisine === name}
                onClick={handleItemClick}
            />)}
            </Menu>
}

            {filterType === 'cuisine' &&  <Menu text vertical style={{marginTop: '4em'}}>
                <Menu.Item header>Location</Menu.Item>
                <Menu.Item
                    name='all-locations'
                    active={activeLocation === 'all-locations'}
                    onClick={handleLocationClick}
                />
                <Menu.Item
                    name='east-london'
                    active={activeLocation === 'east-london'}
                    onClick={handleLocationClick}
                />
                <Menu.Item
                    name='west-london'
                    active={activeLocation === 'west-london'}
                    onClick={handleLocationClick}
                />
                <Menu.Item
                    name='south-london'
                    active={activeLocation === 'south-london'}
                    onClick={handleLocationClick}
                />
                <Menu.Item
                    name='north-london'
                    active={activeLocation === 'north-london'}
                    onClick={handleLocationClick}
                />
            </Menu>}
        </div>
      )
    }

export default ResponsiveSideMenu