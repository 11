import React, { useState, useEffect } from 'react';

import useScreen from '../components/useScreen'
import Footer from '../components/Footer';
import {Helmet} from 'react-helmet'
import './Map.css'

export default () => {
    // const isMobile = useScreen()
    return (
        <>
             <Helmet>
                <title>Map of Restaurants in London - A map which highlights all the restaurants we’ve visited, each containing a link to a written blog piece by us!</title>
                <meta name="description" content=" A map which highlights all the restaurants we’ve visited, each containing a link to a written blog piece by us!" />
            </Helmet>
            <div className='homepage-container'>
                <div className='map-text'>
                    <p>
                        This map highlights all the restaurants we’ve visited in London with Purple Pin Drops indicating our Top Recommendations! 
                        Most Pin Drops will contain a link to a written blog piece by us, and if they don’t, they’ll be coming soon!
                    </p>
                    <br/>
                    <p>
                        Each Pin Drop will also give the precise location of the restaurant, 
                        phone number, website link and average rating from Google Reviewers.
                    </p>
                </div>
                <iframe className='map' title='map' src="https://www.google.com/maps/d/embed?mid=1o0VoUvIxJ9r9Uj0qZhacg7l4myrP56nZ" />
            </div>
            <Footer />
        </>
    );
}