import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import Markdown from 'react-markdown'
import gfm from 'remark-gfm'
import useScreen from '../components/useScreen'
import { Container, Image } from 'semantic-ui-react';
import Infographic from '../components/Infographic';
import Footer from '../components/Footer';
import { getSinglePost } from '../lib/API';
import Loader from '../components/Loader'

const PageNotFound = () => {
  const style = {
    fontSize: '3em',
    fontFamily: 'Pattaya',
    fontWeight: '1000',
    margin: '0 auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return <div style={style}>Page Not Found</div>


}

const Collage = ( { largeImg, smallImg1, smallImg2  } ) => {
  const isMobile = useScreen()
  const collageStyle = isMobile ? 
  { position: 'relative', width: '60%', paddingTop: '50%', margin: '0 auto' } 
  : 
  { float: 'left', position: 'relative', width: '60%', paddingTop: '50%', margin: '0 0.5em 1em 0' }
  const collageImageStyleLarge = { position: 'absolute', top: 0, left: isMobile ? 6 : 15, height: '100%' }
  const leftVal = isMobile ? "calc(62.8% + 6px)" : "calc(62.8% + 15px)"
  const collageImageStyleSmallTop = {position: 'absolute', top: 0, left: leftVal, height: '49.9%'}
  const collageImageStyleSmallBottom = { position: 'absolute', top: '50.2%', left: leftVal, height: '49.9%' }

  return (
  <div className='collage' style={collageStyle}>
    <img alt='Large' className='large-img' style={collageImageStyleLarge} src={largeImg} />
    <img alt='Small Top' className='small-img top' style={collageImageStyleSmallTop} src={smallImg1} />
    <img alt='Small Bottom' className='small-img bottom' style={collageImageStyleSmallBottom} src={smallImg2} />
  </div>
) }

const Blog = ( props ) => {
  const isMobile = useScreen()
  const [ post, setPost ] = useState( props.location.state || {} )
  const infographicStyle = {
    
  }

  useEffect( () => {
    window.scrollTo( { top: 0, behavior: 'smooth' } )
    const getPost = async () => {
      const id = props.match && props.match.params.id
      console.log( id )
      const postData = await getSinglePost( id )
      console.log( postData )
      setPost( { post: postData } )
    }
    Object.keys( post ).length === 0 && getPost()

  }, [ post, props.match ] )

  const blogImageStyleContainer = { display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '2em auto' }
  const blogImageStyleMobile = { margin: '1em auto' }



  if ( Object.keys( post ).length > 0 ) {
    if ( post.post.err ) {
      return (
        <>
          <Container textAlign='center' className='blog-post' style={{padding: '0 0em', height: 'calc(100vh - 160px - 210px'}}>
            <PageNotFound />
          </Container>
          <Footer />
        </>
      )
    } else {
      const { collage_images, contents, rating, price, title, location, created_at, vegan, menu_url, booking_url, website_url, other_branches } = post.post
      const largeImg = collage_images && collage_images[0].url
      const smallImg1 = collage_images && collage_images[1].url 
      const smallImg2 = collage_images && collage_images[2].url 

      return (
        <>
        <Helmet>
                <title>{title} - Bangingbites Review</title>
                <meta name="description" content={contents.replace(/\*(.jpg)>/g, '').replace(/<*>/, '').split('.')[0]}/>
            </Helmet>
        <Container textAlign='center' className='blog-post' style={{padding: '0 0em', marginTop: '3em'}}>
        
          <Collage largeImg={largeImg} smallImg1={smallImg1} smallImg2={smallImg2} />
          <div className='blog-contents' style={{fontSize: '1.6em', paddingTop: '2em' }}>
            <div className='info-section'>
              <h1 style={{position: 'relative', fontFamily: 'Pattaya', fontWeight: 100, fontSize: '1.8em', marginBottom: '0.5em' }}>{title}</h1>
              {/* <h5 style={{marginTop: 0, fontWeight: 0, fontFamily: 'Pattaya', fontSize: '1em'}}>{moment(created_at).format("MMM Do YYYY")}</h5> */}
              <Infographic style={infographicStyle} price={price} location={location} rating={rating} vegan={vegan}/>
              <div className='external-blog-links'>
                {<span className="blog-url website">{website_url ? <a target="_blank" rel="noreferrer" href={website_url} >Website</a> : "No Website Available"}</span>}
                {<span className="blog-url booking">{booking_url ? <a target="_blank" rel="noreferrer" href={booking_url}>Book Here</a> : "No Booking Available"}</span>}
                {<span className="blog-url menu">{menu_url ? <a target="_blank" rel="noreferrer" href={menu_url}>Menu</a> : "No Menu Available"}</span>}
                {other_branches.length > 0 ? <span className="blog-url branches"><u>Other Branches: </u><br/><br/>{other_branches.map( 
                  ( {location}, i ) => 
                    <span className='branch-location'>
                      {location}{i !== other_branches.length - 1 && ", " }
                    </span>)}
                </span> : <span className="blog-url branches">No Other Branches</span>}
              </div>
            </div>
            {<hr className='mobile-blog-separator'/>}
            <span className='blog-text'>
              <Markdown 
                remarkPlugins={[gfm]} 
                children={contents}
                components={{
                  p: ({node, ...props}) => {
                    if( node.children[0].tagName === "strong" && node.children.length === 1 )
                      return <p style={{display: 'flex'}} {...props}></p>
                    else if( node.children[0].tagName === "img" )
                      return <div style={!isMobile ? blogImageStyleContainer : { margin: '2em auto' }} {...props} />
                    else
                      return <p {...props} />
                    
                  },
                  img: ({node, ...props}) => isMobile ? 
                    <Image {...props} size='large' centered style={isMobile ? blogImageStyleMobile : {}}></Image>
                  :
                    <Image {...props} size='large' centered style={isMobile ? blogImageStyleMobile : {}}></Image>,

                  strong: ({node, ...props}) => <strong {...props} style={{justifyContent: "center"}} />
                  }} />
            </span>
          </div>

        </Container>
        <Footer />
        </>
      )
    }
  } else {
    return (
      <>
        <Container textAlign='center' className='blog-post' style={{padding: '0 0em', height: 'calc(100vh - 160px - 210px'}}>
          <Loader loaded={Object.keys( post ).length > 0} />
        </Container>
        <Footer />
      </>)
  }         
}

export default Blog