import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { slide as BurgerMenu } from 'react-burger-menu'
import { cuisineObject, locations } from '../lib/consts'
import { labelise } from '../lib/utils'
import '../BurgerMenu.css'
import 'font-awesome/css/font-awesome.min.css'
import SocialIcon from './SocialIcon'
import useScreen from './useScreen'
import { homepage } from '../../package.json'
import './Header.css'
import SearchBar from './SearchBar'
import Navbar from './Navbar'

const Header = () => {
    const isMobile = useScreen()
    const [ burgerMenuOpen, setBurgerMenuOpen ] = useState( false )

    const menuBlock = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: '10%',
        fontSize: '0.9em',
        outline: 0,
    }

    const lineStyle = {
        height: 5, 
        color: 'white',
        width: '70%', 
        textAlign: 
        'left',
        float: 'left', 
        left: 0,
        marginLeft: '-1%',
        
    }

    const accordionElementStyle = {
        // width: '100%',
    }

    return !isMobile ? (
        <header className="header">
            
            <div className='header-top'>
                <div className='header-search-title' style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <SearchBar />
                <h2 className='header-title' style={{margin: '0 auto', position: 'relative', left: '-11.5vw'}}><Link to={homepage}>Bangingbites</Link></h2>
                    
                </div>
                
                <div className="navbar-socials" style={{fontSize: "1.2em", position: 'absolute', left: '85%'}}>
                    {/* <SocialIcon icon={<FontAwesomeIcon icon={faSearch} />} onClick={ () => searchButtonClick()} /> */}
                    
                    <SocialIcon icon={<FontAwesomeIcon icon={faEnvelope}  />} url="mailto:bangingbites@gmail.com" />
                    <SocialIcon icon={<FontAwesomeIcon icon={faInstagram} />}  url="https://instagram.com/bangingbites" />
                    <SocialIcon icon={<FontAwesomeIcon icon={faPinterest} />}  url="https://www.pinterest.co.uk/bangingbites" />
                </div>
            </div>
            <br/>
            <div className="navlinks" style={{marginRight: "auto", marginTop: "1em"}}>
               <Navbar />
            </div>
            
            
        </header>
    ) : (
        <header className="header" style={{height: 100, padding: "0 3em", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        
            <h2 className='header-title' style={{fontWeight: 1000, fontSize: '2.4em', margin: '0 auto', fontFamily: 'Pattaya'}}><Link to={homepage}>Bangingbites</Link></h2>
            
            <span style={{fontSize: '0.8em'}}>
            <BurgerMenu width="100%" style={{fontSize: '0.5em'}} isOpen={burgerMenuOpen} onStateChange={( state ) => setBurgerMenuOpen( state.isOpen )}>
                <SearchBar onClick={() => setBurgerMenuOpen( false )} />
                <NavLink onClick={() => setBurgerMenuOpen( false )} exact to={homepage} className="menu-item navlink bold" activeClassName="menu-item navlink">Home</NavLink>
                <NavLink onClick={() => setBurgerMenuOpen( false )} exact to={`${homepage}/about`} className="menu-item navlink bold" activeClassName="menu-item navlink">About</NavLink>

                <NavLink onClick={() => setBurgerMenuOpen( false )} exact to={`${homepage}/blogs/top-recommendations`} className="menu-item navlink bold" activeClassName="menu-item navlink">Top Recommendations</NavLink>
                    <NavLink onClick={() => setBurgerMenuOpen( false )} exact to={`${homepage}/map`} className="menu-item navlink bold" activeClassName="menu-item navlink">Map</NavLink>
                <div className='menu-item navlink bold'>London</div>
                <hr style={lineStyle}/>
                <div style={menuBlock}>
                    {locations.map( location => 
                    <NavLink 
                        onClick={() => setBurgerMenuOpen( false )} 
                        to={`${homepage}/blogs/location/${location}/`} 
                        exact className="menu-item navlink sub" 
                        activeClassName="menu-item navlink sub active">
                        {labelise(location).split(' ')[0] + ' London'}
                    </NavLink> )}
                </div>
                <div className='menu-item navlink bold'>Cuisine</div>
                <hr style={lineStyle}/>

                <div style={menuBlock}>
                    <NavLink onClick={() => setBurgerMenuOpen( false )} to={`${homepage}/blogs/cuisine/east-asian`} exact className="menu-item navlink sub" activeClassName="menu-item navlink sub active">East Asian</NavLink>
                    <NavLink onClick={() => setBurgerMenuOpen( false )} to={`${homepage}/blogs/cuisine/south-asian`} exact className="menu-item navlink sub" activeClassName="menu-item navlink sub active">South Asian</NavLink>
                    <NavLink onClick={() => setBurgerMenuOpen( false )} to={`${homepage}/blogs/cuisine/south-east-asian`} exact className="menu-item navlink sub" activeClassName="menu-item navlink sub active">South East Asian</NavLink>
                    {cuisineObject.slice(1).map( cuisine => <NavLink onClick={() => setBurgerMenuOpen( false )} to={`${homepage}/blogs/cuisine/${cuisine.name}`} exact className="menu-item navlink sub" activeClassName="menu-item navlink sub active">{cuisine.label}</NavLink> )}
                </div>

                <div className="navbar-socials-mobile">
                    <SocialIcon icon={<FontAwesomeIcon icon={faEnvelope}  />} url="mailto:bangingbites@gmail.com" />
                    <SocialIcon icon={<FontAwesomeIcon icon={faInstagram} />}  url="https://www.instagram.com/bangingbites/" />
                    <SocialIcon icon={<FontAwesomeIcon icon={faPinterest} />}  url="https://www.pinterest.co.uk/bangingbites" />
                </div>
            </BurgerMenu>
            </span>
            
        </header>
    )
}

export default Header
