export const labelise = str => str.split('-').map( word => word.replace( /^\w/, c => c.toUpperCase() ) ).join(' ')

export const getRandomFromArray = ( arr, n ) => {
    var result = new Array( n ),
        len = arr.length,
        taken = new Array( len )
    n = n > len ? len : n
    while ( n-- ) {
        var x = Math.floor(Math.random() * len);
        result[ n ] = arr[ x in taken ? taken[ x ] : x ];
        taken[ x ] = --len in taken ? taken[ len ] : len;
    }
    return result
}

export const getSetDifference = ( set, otherSet ) => {
    // creating new set to store difference
    var differenceSet = new Set();

    // iterate over the values
    for(var elem of set)
    {
        // if the value[i] is not present
        // in otherSet add to the differenceSet
        if(!otherSet.has(elem))
            differenceSet.add(elem);
    }

    // returns values of differenceSet
    return differenceSet;
}

export const promiseState = ( func, val ) => Promise.resolve( ( resolve, reject ) => {
    func( val )
} )
