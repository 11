import React from 'react'
import Loader from 'react-loader-spinner'

export const loaderStyle = (loaded) => ({
    position: 'fixed',
    top: 'calc(50% - 125px)',
    left: 'calc(50% - 125px)',
    transition: '500ms',
    opacity: !loaded ? 1 : 0,
})

const LoaderCustom = ( { loaded, isMobile } ) => <Loader style={loaderStyle(loaded)} type="Puff" color="#E7D1FF" height={250} width={250} /> 

export const loaderStyleFitted = (loaded, isMobile = false) => ({
    position: 'absolute',
    margin: '0 auto',
    top: isMobile ? '10em': '20em',
    left: 'calc(50% - 125px)',
    transition: '500ms',
    opacity: !loaded ? 1 : 0,
})

export const LoaderFitted = ( { loaded, isMobile = false } ) => <Loader style={loaderStyleFitted(loaded, isMobile)} type="Puff" color="#E7D1FF" height={250} width={250} /> 
 
export default LoaderCustom