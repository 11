import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AOS from 'aos'


import Header from './components/Header'
import Home from './pages/Home'
import Blog from './pages/Blog'
import ResultPage from './pages/ResultPage'
import Map from './pages/Map'
import About from './pages/About'
import Privacy from './pages/Privacy'

import useScreen from './components/useScreen'

import 'semantic-ui-css/semantic.min.css'

import 'aos/dist/aos.css';
import './App.css';
import Search from './pages/Search';
import { homepage } from '../package.json'

// const Pages = () => {
//   const location = useLocation()
//   const transitions = useTransition(location, location => location.pathname, {
//     from: { opacity: 0 },
//     enter: { opacity: 1 },
//     leave: { opacity: 0 },
//   })

//   const routes = [
//     {path: `${homepage}/map`, component: Map, exact: true},
//     {path: `${homepage}/blogs/cuisine/:cuisine`, component: ResultPage, exact: true},
//     {path: `${homepage}/blogs/cuisine/:cuisine/:location`, component: ResultPage, exact: true},
//     {path: `${homepage}/blogs/location/:location`, component: ResultPage, exact: true},
//     {path: `${homepage}/blogs/location/:location/:cuisine`, component: ResultPage, exact: true},
//     {path: `${homepage}/blogs/top-recommendations`, component: ResultPage, exact: true},
//     {path: `${homepage}/search/:term`, component: Search, exact: true},
//     {path: `${homepage}/blog/:id`, component: props => <Blog {...props} />, exact: true},
//     {path: homepage, component: Home, exact: true},
//   ]

//   return transitions.map( ({ item: location, props, key }) => (

//     <animated.div key={key} style={props} className='page'>
//       <Switch location={location}>
//         {routes.map( route => <Route {...route} /> )}
//       </Switch>
//     </animated.div>
//     ) )
// }


const App = () => {
  
  const routes = [
    {path: `${homepage}/map`, component: Map, exact: true},
    {path:  `${homepage}/about`, component: About, exact: true},
    {path:  `${homepage}/privacy`, component: Privacy, exact: true},
    {path: `${homepage}/blogs/cuisine/:cuisine`, component: ResultPage, exact: true},
    {path: `${homepage}/blogs/cuisine/:cuisine/:location`, component: ResultPage, exact: true},
    {path: `${homepage}/blogs/location/:location`, component: ResultPage, exact: true},
    {path: `${homepage}/blogs/location/:location/:cuisine`, component: ResultPage, exact: true},
    {path: `${homepage}/blogs/top-recommendations`, component: ResultPage, exact: true},
    {path: `${homepage}/search/:term`, component: Search, exact: true},
    {path: `${homepage}/blog/:id`, component: props => <Blog {...props} />, exact: true},
    {path: homepage, component: Home, exact: true},
  ]

  useEffect( () => {

    AOS.init( {
      duration: 800,
      mirror: true,
      // anchorPlacement: 'bottom-bottom'
    } )
      
  }, [] )



  return (
    <Router>
      <div className="App">
        <Header />
        {/* <Pages style={{top: isMobile && 0}}/> */}
        <Switch>
          {routes.map( route => <Route key={route.path} {...route} /> )}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
