import React, { useState } from 'react'
import SocialIcon from './SocialIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import 'font-awesome/css/font-awesome.min.css';
import './SearchBar.css'
import { withRouter } from 'react-router';
import useScreen from './useScreen'
import { homepage } from '../../package.json'

export default withRouter( ( { history, onClick } ) => {
    const isMobile = useScreen()
    const [ isActive, setActive ] = useState( isMobile )
    const [ searchValue, setSearchValue ] = useState( "" )

    const search = () => {
        if( !isActive || searchValue === "" ) {
            setActive( true )
        } else {
            const searchUrlValue = encodeURIComponent(searchValue.replace(/\s/g, "-").replace(/[+|&]/g, "and").toLowerCase())
            history.push(`${homepage}/search/${searchUrlValue}`)
            setActive( false )
            setSearchValue( "" ) 
        }
    }

    const searchContainerStyle = {
        // opacity: isActive ? 1 : 0,
        opacity: 1,
        position: 'relative',
        transition: '400ms',
        left: 0,
        height: 50,
        borderRadius: 40,
        width: isMobile ? '75vw' : '23vw',
        backgroundColor: isActive ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0)',
        marginBottom: isMobile && '3em',
    }

    const searchBarStyle = {
        float: 'right',
        width: isActive ? isMobile ? '50vw' : '17vw' : 0,
        left: '3.5vw',
        height: 50,
        transition: '500ms',
        transitionTimingFunction: 'step start',
        position: 'absolute',
        border: 0,
        opacity: isActive ? 1 : 0,
        boderRadius: 20,
        padding: 20,
        fontSize: '1em',
        backgroundColor: 'rgba(255,255,255,0.0)'
    }

    const searchBarStyleInactive = {
        ...searchBarStyle,
        width: 0,
        opacity: 0,
        transition: 'position 0 1s',
        transitionTimingFunction: 'step end',
    }

    const searchButtonStyle = {
        position: 'absolute',
        top: !isMobile ? 13 : 15,
        
        left: !isMobile ? -5 : '68%',
        zIndex: 10,
        fontSize: isMobile ? '1.5em' : '1.2em',
    }

    const searchCloseStyle = {
        // marginLeft: '3em',
        position: 'relative',
        fontSize: '1.2em',
        fontWeight: 1000,
        opacity: isActive ? 1 : 0,
        top: 15,
        left: isMobile ? '-50%' : '42%',
        
    }

    // const searchBar = isActive ? searchBarStyle : searchBarStyleInactive
    const clickFunction = () => {
        onClick && onClick()
        search()
        if( isMobile ) setActive( true )
    }

    const closeFunction = () => {
        if ( !isMobile ) {
            setActive( false )
        } else {
            setSearchValue("")
        }
    }

    const checkInput = ( e ) => {
        const invalidChars = ['/', '(', ')', '.', '}', '{', '[', ']', '<', '>', '\\', '?', '-', '"', "'", '*', '^', '~', '`', '|', '`', '$']
        const isValid = invalidChars.reduce( ( bool, char ) => {
             if ( e.target.value.includes( char ) )
                bool = false
            return bool
        }, true )

        if( isValid )
            setSearchValue(e.target.value)
    }


    return (
        <div className="searchbar" style={{display: 'block', ...searchContainerStyle}}>
            <SocialIcon style={searchButtonStyle} icon={<FontAwesomeIcon icon={faSearch} />} onClick={ () => isActive ? clickFunction() : setActive( true )} />
            {/* <FontAwesomeIcon icon={faSearch} onClick={() => isActive ? search() : setActive( true )}> */}
                <input 
                    type='text'
                    className='searchbar-input' 
                    style={searchBarStyle} 
                    placeholder='Shoreditch Ramen' 
                    value={searchValue} 
                    onChange={(e) => checkInput(e)}
                    onKeyPress={(e) => ( e.keyCode  === 13 || e.which === 13 ) && clickFunction() }
                />
                 <span className='search-close' style={searchCloseStyle} onClick={() => closeFunction()}><FontAwesomeIcon icon={faTimesCircle} /></span>
            
           
        </div>
    ) 
} )

// <SocialIcon style={searchButtonStyle} icon={<FontAwesomeIcon icon={faSearch} />} onClick={ () => search()} />