import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'
import useScreen from '../components/useScreen'
import { Card, Grid, Transition, Loader } from 'semantic-ui-react'
import Image1 from '../assets/img (1).jpg'
import Image2 from '../assets/img (2).jpg'
import Image3 from '../assets/img (3).jpg'
import { withRouter } from 'react-router-dom';
import Footer from '../components/Footer';
import BlogCard from '../components/BlogCard'
import { titleTextStyle } from '../lib/consts'
import { searchPosts } from '../lib/API';
import NoResultsText from '../components/NoResultsText';
import { labelise } from '../lib/utils';
import { LoaderFitted } from '../components/Loader'

export default withRouter( ({ history, match } )  => {
    const isMobile = useScreen()
    const [ results, setResults ] = useState( null )
    const searchTerm = decodeURIComponent(match.params.term)

    useEffect( () => {

        const getResults = async () => {
            const data = await searchPosts( searchTerm )
            setResults( data )
        }
        
        getResults()

    }, [searchTerm] )

        return (
            <>
            {/* <Helmet>
                <title>Search for {labelise(searchTerm)} - Bangingbites</title>
                <meta name="description" content={`Search for ${labelise(searchTerm)} - Bangingbites`}/>
            </Helmet> */}
            <Helmet>
                <title>Bangingbites - London Restaurant Review and Food Blog</title>
                <meta name="description" content="A London restaurant review site that offers a user-friendly guide to reflect the diversity in cuisine this city has to offer!" />
            </Helmet>
            <div className="home-container">
              <Grid centered style={{margin: '0 auto', padding: '0em 3em'}} padded stackable>
                <h1 style={titleTextStyle(isMobile)}> Results for <br/>"{labelise(searchTerm)}" </h1>
                  <Grid.Row>
                      <Grid.Column width={16} style={{padding: '0 12em'}}>
                          <Card.Group stackable doubling itemsPerRow={isMobile ? 1 : 3} animation='drop' duration={600}>
                              {/* {!posts && <LoaderFitted loaded={posts !== null} /> } */}
                              {!results && <LoaderFitted loaded={results !== null} /> }
                              {results && results.length > 0  && results.map( post => <BlogCard {...post} />)}
                              {results && results.length === 0 && <NoResultsText text="No Results Found" />}
                          </Card.Group>
                      </Grid.Column>
                  </Grid.Row>
              </Grid>
              </div>
            <Footer />
  
          </>
    )
} )