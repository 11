import React from 'react'
import useScreen from './useScreen'
import InfographicImage from '../assets/infographic.png'
import LocationImg from '../assets/location-icon.svg'
import PriceImg from '../assets/price-icon.svg'
import VeganImg from '../assets/vegan-icon.svg'
import RatingImg from '../assets/rating-icon.svg'
import { Image } from 'semantic-ui-react'

export default ( { rating, location, price, style, vegan } ) => {
    const isMobile = useScreen()
    const textStyle = {fontSize: '0.3em', margin: '0 0', textAlign: 'center', color: 'white', maxWidth: 160}
    const headerStyle = { fontWeight: 1000, fontSize: '0.3em', marginTop: '10px'}
    const infographicContainerStyle = {
        position:'relative',
        marginBottom: '2em',
        marginTop: '0em',
        fontSize: '1em', 
        // maxWidth: 500,
        transition: '300ms',
        ...style,
    }

    const infographicImageStyle = {
        display: 'inline',
        transform: isMobile ? 'scale(1)' : 'scale(1)',
    }
    return (
        <div className='infographic-section'>
            <div className='infographic-icon' style={infographicContainerStyle}>
                <Image className='infographic-img' src={LocationImg} size='tiny' style={infographicImageStyle}/>
                <div className='infographic-text'>LOCATION</div>
                <div className='infographic-details'>{location}</div>
            </div>
            <div className='infographic-icon' style={infographicContainerStyle}>
                <Image className='infographic-img' src={PriceImg} size='tiny' style={infographicImageStyle}/>
                <div className='infographic-text'>PRICE</div>
                <div className='infographic-details'>£{price} pp</div>

            </div>
            <div className='infographic-icon' style={infographicContainerStyle}>
                <Image className='infographic-img' src={RatingImg} size='tiny' style={infographicImageStyle}/>
                <div className='infographic-text'>RATING</div>
                <div className='infographic-details'>{rating}/10</div>

            </div>
            <div className='infographic-icon' style={infographicContainerStyle}>
                <Image className='infographic-img' src={VeganImg} size='tiny' style={infographicImageStyle}/>
                <div className='infographic-text'>VEGAN</div>
                <div className='infographic-details'>{vegan ? "Vegan Friendly" : "Not Vegan Friendly"}</div>

            </div>
        </div>
        
    )

    return (
        <div className='infographic-section' style={infographicContainerStyle}>
            <Image className='infographic-img' src={InfographicImage} size='medium' style={infographicImageStyle}/>
            {/* <span style={{position: 'absolute', top: 0, left: 0}} ><FontAwesomeIcon icon={faTruckMoving} /></span> */}
        </div>
        
    )

} 

 {/* <div style={{position: 'absolute', top: height, left: '6.5%'}} className='delivery'>
                <FontAwesomeIcon icon={faTruckMoving} />
                <p style={{...headerStyle, fontSize: '0.5em'}} > Delivery</p>
                </div>
                <div style={{position: 'absolute', top: height, left: '21.5%'}} className='ocassion'>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <p style={headerStyle} > Ocassion</p>
                </div>
                <div style={{position: 'absolute', top: height, left: '40.5%'}} className='rating'>
                    <FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} />
                    <p style={headerStyle} > Rating</p>
                </div>
                <div style={{position: 'absolute',  top: height, left: '62%'}} className='location'>
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                    <p style={headerStyle} > Location</p>
                </div>
                <div style={{position: 'absolute', top: height, left: '84.5%'}} className='rating'>
                    <FontAwesomeIcon icon={faPoundSign} />
                    <p style={{...headerStyle, fontSize: '0.5em'}} > Price</p>
                </div> */}

