import React, { useState, useEffect } from 'react'


export default () => {
    const breakpoint = 1024
    const [ isMobile, setIsMobile ] = useState( window.innerWidth <= breakpoint )
    useEffect( () => {
        const handleResize = () => {
            setIsMobile( window.innerWidth <= breakpoint )
        }
        window.addEventListener('resize', handleResize )
        return () =>  window.removeEventListener('resize', handleResize)

    }, [] )

    return isMobile

}