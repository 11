import { labelise } from './utils'

export const cuisines = [
    'asian',
    'afro-caribbean',
    'south-american',
    'european',
    'mediterranean',
    'brunch',
    'desserts',
    'burgers-and-chicken',
    'vegan',
]

export const cuisineLabels = [
    'Asian',
    'Afro-Caribbean',
    'South American',
    'European',
    'Mediterranean',
    'Brunch',
    'Desserts',
    'Burgers & Chicken',
    'Vegan',
]

export const sideMenuCuisines = [
    'east-asian',
    'south-asian',
    'south-east-asian',
    ...cuisines.slice(1)
]

export const cuisineObject = cuisines.map( ( cuisine, index ) => ( { name: cuisine, label: cuisineLabels[index] } ) )


export const locations = [
    'east-london',
    'west-london',
    'south-london',
    'north-london',
]


export const titleTextStyle = isMobile => ({
    textAlign: 'center',
    fontFamily: 'Pattaya',
    fontSize: '2.2em',
    fontWeight: 1,
    padding: '0.5em',
    border: '2px solid black',
    width: 'auto',
    maxWidth: '600px',
    margin: '0 auto',
    marginBottom: !isMobile ? '1em' : 0,
    minWidth: 300

    // backgroundColor: 'lightgrey',
    // borderRadius: '10em',
})

export const titleTextStyleTopRecommended = {
    ...titleTextStyle,
    margin: '0 auto',
    // backgroundColor: 'lightgrey',
    // borderRadius: '10em',
}


export const buttonStyle = {
    textAlign: 'center',
    fontFamily: 'Pattaya',
    fontSize: '2.2em',
    fontWeight: 1,
    padding: '0.5em',
    border: '2px solid black',
    width: 'auto',
    maxWidth: '600px',
    margin: '0 auto',
    marginTop: '1em',
    minWidth: 200,
    cursor: 'pointer',
    transition: '600ms'

    // backgroundColor: 'lightgrey',
    // borderRadius: '10em',
}
