import React from 'react';
import useScreen from '../components/useScreen'
import { Image, Container } from 'semantic-ui-react'
import AboutImage from '../assets/about.jpg'
import Footer from '../components/Footer';
import {Helmet} from 'react-helmet'


const About = () => {

    return (
        <>
            {/* <Helmet>
                <title>About - Bangingbites</title>
                <meta name="description" content="About Bangingbites" />
            </Helmet> */}
             <Helmet>
                <title>Bangingbites - London Restaurant Review and Food Blog</title>
                <meta name="description" content="Hello and welcome to our website - bangingbites.com!" />
            </Helmet>
            <Container className='home-container about' fluid style={{width: 'auto', textAlign: 'left'}}>
                {/* <div data-aos="fade">
                    <Image src={AboutImage} size="large" centered />
                </div>
                 */}
                <h1 data-aos="fade">Hello and welcome to our website - bangingbites.com!</h1>

                <p data-aos="fade">
                    We are Kimberly and James, the people behind bangingbites! We met in 2014 and started our Instagram account in 2018. 
                </p>

                <p data-aos="fade">
                    After our first post-university holiday in Valencia , our phones became full of pictures of the various dishes we experienced in a place commonly known as the food capital of Spain. 
                    We then came to the realisation that Instagram was the perfect platform for us to creatively express ourselves and our culinary experiences.
                </p>
                <p data-aos="fade">
                    Fast forward to 2021, we have fallen in love with the food in our own city - London.<br/><br/>
                    We are of Filipino and Nigerian descent respectively, and on our culinary journey across London we started to realise that our own 
                    culture and heritage amongst many others were being severely underrepresented and not as widely covered within the foodie community as we’d hoped. 
                    We therefore developed a strong desire to shine a light and spread awareness of dishes we already know and love, alongside others many other people may have not encountered before.
                </p>

                <p data-aos="fade">
                    After three years on Instagram, we felt the urge to expand our accessibility by creating this website. 
                    Bangingbites.com is a complete catalogue of our own experiences of all the restaurants we have visited in London so far.
                </p>

                <p data-aos="fade">
                    When trying to find your next visit, you can either search for keywords or filter by location and/or cuisine. 
                    We also rate each visit out of ten, taking the food, service, ambience and value for money into consideration, 
                    all of which can be seen quickly in a handy infographic at the top of each written piece.
                    We’ve also included the average price you can expect to spend in the restaurant, this price should cover a main meal and a drink when applicable.
                 </p>

                <p data-aos="fade">
                    We hope that this website will serve as a tool and personal guide to all the diverse cuisine that London has to offer, 
                    giving you a comprehensive and varied outline of our own food experiences.
                </p>

                <p data-aos="fade">
                    This website has been over a year in the making, built from scratch and we are so excited to finally share it with you! 
                </p>

                <p data-aos="fade">
                    We hope you enjoy your time here and happy scrolling!
                </p>
                </Container>
            <Footer/>
        </>
    );
}


    


export default About
